<template>
  <div>
    <div class="searchBox" v-if="type == 1">
      <el-input
        class="input"
        v-model="input"
        placeholder="请输入内容"
      ></el-input>
      <el-button @click="search" class="searchBoxR">搜索</el-button>
    </div>
    <div class="search2Box" v-else>
      <el-input
        class="input2"
        v-model="input"
        placeholder="请输入内容"
      ></el-input>
      <el-button @click="search" class="searchBoxR2">搜索</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "search",
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      input: "",
    };
  },
  mounted() {
   
  },
  methods:{
    search(){
      this.$emit("search",this.input)
    }
  }
};
</script>

<style lang="less" scoped>
.searchBox {
  width: 610px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .input {
    width: 480px;
    height: 48px;
    font-size: 16px;
    /deep/ .el-input__inner {
      height: 48px;
    }
  }
  .searchBoxR {
    width: 110px;
    height: 48px;
    background: #00d8c3;
    font-size: 18px;
    color: #ffffff;
    &.is-active,
    &:active {
      border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
      outline: none; /* 移除默认的轮廓线 */
    }
  }
}
.search2Box {
  width: 600px;
  height: 48px;
  margin: 0 auto;
  display: flex;
  position: relative;
  z-index: 100;
  .input2 {
    width: 490px;
    height: 48px;
    background: #ffffff;

      /deep/ .el-input__inner {
        width: 100% !important;
        height: 100% !important;
      }
    
  }
  .searchBoxR2 {
    width: 100px;
    height: 48px;
    background: #00d8c3;
    font-size: 18px;
    color: #ffffff;
    border: 1px solid #00d8c3;
    border-radius: 0;
    &.is-active,
    &:active {
      border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
      outline: none; /* 移除默认的轮廓线 */
    }
  }
}
</style>