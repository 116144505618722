<template>
  <div>
    <headers />
    <div class="head">
      <img src="@/assets/images/forumDetailsBj.png" />
      <div class="title">常见问题</div>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item  to="/forum/index">常见问题</el-breadcrumb-item>
        <el-breadcrumb-item>{{details.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="title">{{ details.title }}</div>
      <div class="line"></div>
      <div class="text" v-html="details.way">
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { qaView } from "@/api/lx";

export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      id: "",
      details: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getQaView();
  },
  mounted() {},
  methods: {
    getQaView() {
      qaView(this.id).then((res) => {
        this.details = res.data;
      });
    },
    toSolutionDetails() {
      this.$router.push({
        path: "/information/solutionDetails",
        query: { id: 123 },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 240px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 1;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 1400px;
  margin: 0 auto;
  margin-top: 26px;
  min-height: 492px;
  padding: 44px 50px;
  box-sizing: border-box;
  background-color: #fff;
}
.title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 22px;
  color: #00d8c3;
  line-height: 30px;
}
.line {
  width: 100%;
  height: 1px;
  background: #e6edf0;
  margin-top: 30px;
}
.text {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #525f66;
  margin-top: 30px;
}
</style>